import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const DepartmentalComparisonChart = ({ data }) => {
  // Map the data to the structure needed for the chart
  const chartData = data.map((department) => ({
    name: department.department || "Unknown",
    AttendanceRate: parseFloat(department.attendance_rate),
    AbsenteeismRate: parseFloat(department.absenteeism_rate),
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={chartData}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="AttendanceRate" fill="#8884d8" />
        <Bar dataKey="AbsenteeismRate" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DepartmentalComparisonChart;
