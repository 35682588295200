/** @format */

import React from "react";
import { FaHome, FaUser, FaClock } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { IoSettings } from "react-icons/io5";

const sidebarData = [
  {
    _id: "1",
    name: "Employee Management",
    icon: <MdDashboard />,
    requiredPermissionsformainfeatures: ["1"],
    subModules: [
      {
        _id: "1.1",
        name: "Employee Dashboard",
        url: "/emp-dashboard",
        requiredPermissionsforsubfeatures: ["100"],
      },
      {
        _id: "1.2",
        name: "Employee Onboarding",
        url: "/emp-onboard",
        requiredPermissionsforsubfeatures: ["101"],
      },
      {
        _id: "1.3",
        name: "View Employee Details",
        url: "/emp-details",
        requiredPermissionsforsubfeatures: ["102"],
      },
      // {
      //   _id: "1.4",
      //   name: "History Login Details (Management)",
      //   url: "/history-login-details",
      //   requiredPermissionsforsubfeatures: ["103"],
      // },
      {
        _id: "1.5",
        name: "Employee Edit Details",
        url: "/history-logged",
        requiredPermissionsforsubfeatures: ["104"],
      },
    ],
  },

  {
    _id: "2",
    name: "Time & Attendance",
    icon: <FaClock />,
    requiredPermissionsformainfeatures: ["2"],
    subModules: [
      {
        _id: "2.1",
        name: "Live Dashboard",
        url: "/time-attendance-dashboard",
        requiredPermissionsforsubfeatures: ["120"],
      },
      {
        _id: "2.2",
        name: "Time Management",
        url: "/time-management",
        requiredPermissionsforsubfeatures: ["121"],
      },

      {
        _id: "2.3",
        name: "Departmental Comparison",
        url: "/department-comparison",
        requiredPermissionsforsubfeatures: ["122"],
      },

      {
        _id: "4.1",
        name: "Absence Report",
        url: "/absence-report",
        requiredPermissionsforsubfeatures: ["160"],
      },
      {
        _id: "4.2",
        name: "Summary Report",
        url: "/summary-report",
        requiredPermissionsforsubfeatures: ["161"],
      },
      {
        _id: "4.3",
        name: "Checkin-checkout Report",
        url: "/checkin-checkout-Report",
        requiredPermissionsforsubfeatures: ["162"],
      },
      // {
      //   _id: "4.4",
      //   name: "Attendance History Report",
      //   url: "/attendance-history-report",
      //   requiredPermissionsforsubfeatures: ["163"],
      // },
    ],
  },

  // {
  //   _id: "4",
  //   name: "Reports",
  //   icon: <TbReportAnalytics />,
  //   requiredPermissionsformainfeatures: ["4"],
  //   subModules: [

  //   ],
  // },
  {
    _id: "3",
    name: "Leave Management",
    icon: <FaUser />,
    requiredPermissionsformainfeatures: ["3"],
    subModules: [
      // {
      //   _id: "3.1",
      //   name: "Leaves Dashboard",
      //   url: "/leave-info",
      //   requiredPermissionsforsubfeatures: ["140"],
      // },
      {
        _id: "3.2",
        name: "Employee Leave Management",
        url: "/leave-management",
        requiredPermissionsforsubfeatures: ["141"],
      },
      {
        _id: "3.3",
        name: "Leave Approval process",
        url: "/leave-approve",
        requiredPermissionsforsubfeatures: ["142"],
      },
      {
        _id: "3.4",
        name: "Date Restrictions",
        url: "/restricted-date",
        requiredPermissionsforsubfeatures: ["143"],
      },
      {
        _id: "3.5",
        name: "Leave Request",
        url: "/leave-request",
        requiredPermissionsforsubfeatures: ["144"],
      },

      // {
      //   _id: "3.7",
      //   name: "Current Leave Balance Reports",
      //   url: "/leave-balance-report",
      //   requiredPermissionsforsubfeatures: ["146"],
      // },
    ],
  },

  {
    _id: "5",
    name: "Settings",
    icon: <IoSettings />,
    requiredPermissionsformainfeatures: ["5"],
    subModules: [
      {
        _id: "5.1",
        name: "User Management",
        url: "/create-user-account",
        requiredPermissionsforsubfeatures: ["180"],
      },
      {
        _id: "5.2",
        name: "Role Management",
        url: "/user-permission",
        requiredPermissionsforsubfeatures: ["181"],
      },
      {
        _id: "5.3",
        name: "Supervisor",
        url: "/Supervisor",
        requiredPermissionsforsubfeatures: ["182"],
      },
      {
        _id: "5.4",
        name: "Designation & Department",
        url: "/Designation",
        requiredPermissionsforsubfeatures: ["183"],
      },
    ],
  },

  {
    name: "Home",
    url: "/home",
    icon: <FaHome />,
  },
];

export default sidebarData;
