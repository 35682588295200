/** @format */

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import Sidebar_Logo from "../../assets/LOGO HRIS 6.png";
import usePermissions from "../permissions/permission";
import {
  TbLayoutSidebarLeftExpand,
  TbLayoutSidebarRightExpand,
} from "react-icons/tb";
import sidebarData from "./sidebar_data";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [expandedMenu, setExpandedMenu] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { hasPermission, hasAnyFeaturePermission, permissions } =
    usePermissions();

  const toggleSubMenu = (name) => {
    setExpandedMenu((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const isSelectedPath = (path) => {
    return (
      location.pathname === path || location.pathname.startsWith(`${path}/`)
    );
  };

  const renderSubModules = (subModules, parentPath = "") => {
    return (
      <ul className={`ml-4 ${!isOpen && "hidden"}`}>
        {subModules.map((subModule) => {
          if (
            subModule &&
            (hasPermission(subModule.requiredPermissionsforsubfeatures) ||
              hasAnyFeaturePermission(
                subModule.subFeatures,
                "requiredPermissionsforsubfeatures"
              ))
          ) {
            const currentPath = `${parentPath}${subModule.url}`;
            const isSelected = isSelectedPath(currentPath);
            const hasSelectedSubFeature = subModule.subFeatures?.some(
              (subFeature) =>
                isSelectedPath(`${currentPath}${subFeature.url}`) ||
                subFeature.subFeatures?.some((subSubFeature) =>
                  isSelectedPath(
                    `${currentPath}${subFeature.url}${subSubFeature.url}`
                  )
                )
            );
            return (
              <li
                key={subModule._id}
                className={`flex items-center p-2 ${
                  isSelected || hasSelectedSubFeature
                    ? "font-bold text-white"
                    : "text-gray-200"
                }`}
              >
                {isSelected && (
                  <span className="mr-2 w-2 h-2 bg-white rounded-full"></span>
                )}
                <Link to={currentPath}>{subModule.name}</Link>
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const handleLogout = () => {
    // Implement your logout logic here
    console.log("Logging out...");
    // Redirect to login or another appropriate page after logout
    navigate("/login");
  };

  return (
    <div
      className={`fixed top-0 left-0 h-screen bg-primary_purple p-5 pt-8 transition-width duration-300 ${
        isOpen ? "w-64" : "w-20"
      } flex flex-col`}
    >
      <button
        onClick={toggleSidebar}
        className="absolute -right-3 top-9 w-10 h-10 rounded-full flex items-center justify-center bg-primary_purple"
      >
        {isOpen ? (
          <TbLayoutSidebarRightExpand className="text-white" size={24} />
        ) : (
          <TbLayoutSidebarLeftExpand className="text-white" size={24} />
        )}
      </button>
      <div className="text-center text-white mb-6">
        <img
          src={Sidebar_Logo}
          alt="Logo"
          className={`mx-auto ${isOpen ? "w-24 h-24" : "w-10 h-10"}`}
        />
        {isOpen && (
          <h1 className="text-2xl font-semibold">
            The British School in Colombo
          </h1>
        )}
      </div>
      <div className="overflow-y-auto flex-1">
        <ul>
          {sidebarData.map((module) => {
            const isModuleSelected = isSelectedPath(module.url);
            const hasSubModules =
              module.subModules && module.subModules.length > 0;
            if (
              module &&
              (hasPermission(module.requiredPermissionsformainfeatures) ||
                hasAnyFeaturePermission(
                  module.features,
                  "requiredPermissionsforsubfeatures"
                ))
            ) {
              return (
                <li key={module._id} className="mt-1">
                  <div
                    className={`flex items-center p-2 rounded-md cursor-pointer ${
                      isModuleSelected ? "bg-[#8764A0]" : "hover:bg-[#8764A0]"
                    } text-white`}
                    onClick={() =>
                      hasSubModules
                        ? toggleSubMenu(module._id)
                        : navigate(module.url)
                    }
                  >
                    {module.icon && <span>{module.icon}</span>}
                    {isOpen && <span className="ml-2">{module.name}</span>}
                    {isOpen && hasSubModules && (
                      <span className="ml-auto">
                        {expandedMenu[module._id] ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    )}
                  </div>
                  {expandedMenu[module._id] &&
                    hasSubModules &&
                    renderSubModules(module.subModules, module.url)}
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>
      {/* Logout Button */}
      <div className="p-4">
        <button
          onClick={handleLogout}
          className="flex items-center justify-center p-3 w-full text-white bg-[#a868d2] font-bold rounded-lg"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
