import React, { useState, useEffect } from "react";
import { FaBagShopping } from "react-icons/fa";
import { GiOfficeChair } from "react-icons/gi";
import { PiSealWarningLight } from "react-icons/pi";
import { TbBeach } from "react-icons/tb";
import { CiClock2 } from "react-icons/ci";
import { FaShoppingBag } from "react-icons/fa";

const Leave_process_popup = ({ togglePopup, employeeNo }) => {
  const [leaveData, setLeaveData] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(true);
  const API_URL = process.env.REACT_APP_FRONTEND_URL;

  useEffect(() => {
    const fetchLeaveData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/v1/hris/leave/GetLeaveCountsdaypart?employee_no=${employeeNo}`
        );
        const data = await response.json();
        setLeaveData(data);
      } catch (error) {
        console.error("Error fetching leave counts:", error);
      }
    };

    fetchLeaveData();
  }, [employeeNo, API_URL]);

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  if (!isFormOpen) {
    return null;
  }

  // Check if leaveData is null before destructuring
  const { employeeInfo, currentLeave, actualLeaveCounts } = leaveData || {
    employeeInfo: {},
    currentLeave: {},
    actualLeaveCounts: {},
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg w-[80%]">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">
            Leaves Taken: Category Wise
          </h2>
          <button
            onClick={togglePopup}
            className="text-gray-500 hover:text-gray-700 transition duration-300"
          >
            Close
          </button>
        </div>
        <form>
          <div className="flex gap-6 items-center justify-center">
            <div className="flex gap-20 items-center">
              <label className="block text-sm font-medium text-gray-700">
                Employee ID:
              </label>
              <input
                type="text"
                value={employeeNo}
                readOnly
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="flex gap-20 items-center">
              <label className="block text-sm font-medium text-gray-700">
                Employee Name:
              </label>
              <input
                type="text"
                value={employeeInfo?.employee_fullname || ""}
                readOnly
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
            <div className="flex gap-20 items-center">
              <label className="block text-sm font-medium text-gray-700">
                Department:
              </label>
              <input
                type="text"
                value={employeeInfo?.department || ""}
                readOnly
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
              />
            </div>
          </div>
          <div className="mt-10 flex justify-center">
            <div className="grid grid-cols-5 grid-flow-rows gap-[250px] ml-[75px]">
              {/* Annual Leave Card */}
              <div className="border border-black p-3 rounded-xl w-[300px]">
                <div className="flex justify-between items-center mt-5">
                  <PiSealWarningLight className="w-5 h-5" />
                  <p className="font-semibold text-green-400">
                    Remaining Count
                  </p>
                </div>
                <p className="mt-5 font-semibold">Annual Leave Count</p>
                <div className="flex">
                  <p className="text-[30px] font-semibold text-red-600">
                    {currentLeave?.anualleavecount || 0}
                  </p>
                  <p className="text-[30px] font-semibold">
                    /{actualLeaveCounts?.annualLeaveCount || 0}
                  </p>
                </div>
              </div>
              {/* Sick Leave Card */}
              <div className="border border-black p-3 rounded-xl w-[300px]">
                <div className="flex justify-between items-center mt-5">
                  <TbBeach className="w-5 h-5" />
                  <p className="font-semibold text-green-400">
                    Remaining Count
                  </p>
                </div>
                <p className="mt-5 font-semibold">Sick Leave Count</p>
                <div className="flex">
                  <p className="text-[30px] font-semibold text-red-600">
                    {currentLeave?.medicalleavecount || 0}
                  </p>
                  <p className="text-[30px] font-semibold">
                    /{actualLeaveCounts?.medicalLeaveCount || 0}
                  </p>
                </div>
              </div>
              {/* Casual Leave Card */}
              <div className="border border-black p-3 rounded-xl w-[300px]">
                <div className="flex justify-between items-center mt-5">
                  <CiClock2 className="w-5 h-5" />
                  <p className="font-semibold text-green-400">
                    Remaining Count
                  </p>
                </div>
                <p className="mt-5 font-semibold">Casual Leave Count</p>
                <div className="flex">
                  <p className="text-[30px] font-semibold text-red-600">
                    {currentLeave?.casualleavecount || 0}
                  </p>
                  <p className="text-[30px] font-semibold">
                    /{actualLeaveCounts?.casualLeaveCount || 0}
                  </p>
                </div>
              </div>

              {/* No Pay Leave Card */}

              <div className="border border-black p-3 rounded-xl w-[300px]">
                <div className="flex justify-between items-center mt-5">
                  <FaShoppingBag className="w-5 h-5" />
                  <p className="font-semibold text-green-400">
                    Remaining Count
                  </p>
                </div>
                <p className="mt-5 font-semibold">Nopay Leave Count</p>
                <div className="flex">
                  <p className="text-[30px] font-semibold text-red-600">
                    {currentLeave?.noPayLeaveCount || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Leave_process_popup;
